<template>
  <div class="ex-outgoing">
    <ex-outgoing-countdown
      :external-rate="calculation.rate"
      :currency-buy="form.currencyBuy"
      :currency-pay="form.currencyPay"
      :restart-trigger="restartTimerTrigger"
      :stop-trigger="stopTimerTrigger"
      @restartTimer="restartTimer"
      @errorRate="handleErrorRate"
      @fetchRate="validateAndMakeRequest"
    />
    <div class="ex-outgoing__form">
      <ex-alert v-if="!hasBillingData" type="danger" class="ex-outgoing__message">
        {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
      </ex-alert>
      <el-form v-loading="isFetching" :model="form" ref="form" label-position="top">
        <el-form-item
          label="Address"
          prop="address"
          :rules="[
            ruleRequired,
            ruleMaxLength(64),
            {validator: validateMoneyAccount, trigger: 'blur'},
          ]"
        >
          <el-input
            v-model.trim="form.address"
            placeholder="ex: 15sBdDJULzqWmnn4KyvqSz1Bz8r7gMwCMQ"
            autocomplete="on"
            name="wallet"
          />
        </el-form-item>
        <el-form-item
          prop="fiatAmount"
          :rules="[
            ruleRequired,
            {validator: locks[0] ? (rule, value, callback) => callback() : validateAmount, trigger: 'change'},
          ]">
          <template #label>
            <div class="ex-outgoing__label">Gross amount</div>
            <ex-tooltip-typical>
              Gross amount stands for the total amount before any deductions and fees
            </ex-tooltip-typical>
          </template>
          <div class="ex-outgoing__input-group">
            <ex-input
              v-loading="isCalculating && locks[0]"
              :is-lock="locks[0]"
              :currency="form.currencyPay"
              :value="form.fiatAmount"
              @input="handleChangeFiatAmount"
              @change-lock="handleChangeLock(0, $event)"
              class="ex-outgoing__input-item"
              has-lock
            >
              <el-button slot="append" @click="handleChangeFiatAmount(maxFiatAmount)">MAX</el-button>
            </ex-input>
            <div class="ex-outgoing__image">
              <img :src="currencyPayIcon" alt="icon" />
            </div>
          </div>
        </el-form-item>
        <div class="ex-outgoing__coin-list">
          <ex-coin-list
            :highlight="form.currencyBuy"
            :list="payList"
            :value="form.currencyPay"
            @input="form.currencyPay = $event.currency"
          />
        </div>
        <el-form-item
          label="You buy"
          prop="cryptoAmount"
          :rules="[
            ruleRequired,
            {validator: locks[1] ? (rule, value, callback) => callback() : validateAmount, trigger: 'change'},
          ]">
          <template #label>
            <div class="ex-outgoing__label">Purchase amount</div>
            <ex-tooltip-typical>
              The amount of cryptocurrency you want to purchase for withdrawal
            </ex-tooltip-typical>
          </template>
          <div class="ex-outgoing__input-group">
            <ex-input
              v-loading="isCalculating && locks[1]"
              :has-lock="true"
              :is-lock="locks[1]"
              :currency="form.currencyBuy"
              :value="form.cryptoAmount"
              @input="handleChangeCryptoAmount"
              @change-lock="handleChangeLock(1, $event)"
              class="ex-outgoing__input-item"
            />
            <div class="ex-outgoing__image">
              <img :src="currencyBuyIcon" alt="icon" />
            </div>
          </div>
        </el-form-item>
        <div class="ex-outgoing__coin-list">
          <ex-coin-list
            :highlight="form.currencyPay"
            :list="buyList"
            :value="form.currencyBuy"
            @input="form.currencyBuy = $event.currency"
          />
        </div>
        <ex-alert v-if="SPECIAL_CURRENCIES_LIST.includes(form.currencyBuy)" type="warning" class="ex-outgoing__message">
          {{WARNING_MESSAGE_CURRENCY_EXPERIMENTAL}}
        </ex-alert>
        <el-form-item
          prop="receiveAmount"
          :rules="[
            ruleRequired,
            {validator: locks[2] ? (rule, value, callback) => callback() : validateAmount, trigger: 'change'},
          ]">
          <template #label>
            <div class="ex-outgoing__label">Net amount</div>
            <ex-tooltip-typical>
              Net amount stands for the amount left over after all deductions are made
            </ex-tooltip-typical>
          </template>
          <div class="ex-outgoing__input-group">
            <ex-input
              v-loading="isCalculating && locks[2]"
              :is-lock="locks[2]"
              :currency="form.currencyBuy"
              :value="form.receiveAmount"
              @input="handleChangeAmountReceiveAmount"
              @change-lock="handleChangeLock(2, $event)"
              class="ex-outgoing__input-item"
              has-lock
            />
            <div class="ex-outgoing__image">
              <img :src="currencyBuyIcon" alt="icon" />
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="isNetworkFeeAvailable" prop="currencyFee" :rules="[ruleRequired]">
          <template #label>
            <div class="ex-outgoing__label">Network Fee Currency</div>
            <ex-tooltip-typical>
              Cost of transferring funds to your wallet on a blockchain
              which depends on the current network congestion and is paid in a chosen currency
            </ex-tooltip-typical>
          </template>
          <el-select v-model="form.currencyFee" :loading="mix_networkFeeMixin.isFetching">
            <el-option v-for="item in currencyList" :key="item" :value="item" />
          </el-select>
          <span v-loading="form.address && isCalculating" class="ex-outgoing__fee-info">
            {{networkFeeInfo}}
          </span>
        </el-form-item>
        <el-form-item label="Reference" prop="reference" :rules="[ruleMaxLength(64)]">
          <el-input v-model.trim="form.reference" />
        </el-form-item>
      </el-form>
      <ex-summary-info
        :address="form.address"
        :gross-amount="form.fiatAmount"
        :net-amount="form.receiveAmount"
        :gross-currency="form.currencyPay"
        :net-currency="form.currencyBuy"
        :network-fee="calculation.hash ? calculation.amountNetworkFee : 0"
        :service-fee="fee"
        extended
      />
      <ex-alert type="warning" class="ex-outgoing__warning">
        Please be advised, that fees displayed while you input data in the form are
        indicative and final fees are deducted after you press "Submit Order" due to
        a high volatility of virtual assets on the exchange.
      </ex-alert>
      <ex-alert v-if="!hasBillingData" type="danger" class="ex-outgoing__message">
        {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
      </ex-alert>
      <el-button
        :loading="isLoading"
        :disabled="isCalculating || isFetching || !hasBillingData"
        @click="handleSubmit"
        type="primary"
      >
        SUBMIT ORDER
      </el-button>
    </div>
  </div>
</template>

<script>
// utils
import {filterCoinByList} from '@/utils/filters';
import {getCurrency} from '@/utils/exchange';
import {validateWalletAddress} from '@/utils/validate';
import {
  cutPrecisionByCurrency,
  getPairCurrencies,
  isCrypto,
  numberFromLocaleString,
} from '@/utils/converters/currency';
import {ruleMaxLength, ruleRequired} from '@/utils/elementUITypicalValidation';
import {socketRequest} from '@/utils/socket';
import {fromSecondsToFormat} from '@/utils/converters/time';

// mixin
import networkFeeMixin from '@/mixins/networkFeeMixin';

// api
import TunnelApi from '@/api/tunnel.api';

// lib
import {mapState, mapGetters} from 'vuex';

// component
import ExCoinList from '@/components/ex-coin-list';
import ExAlert from '@/components/ex-alert';
import ExOutgoingCountdown from '@/pages/gateway/ex-outgoing-countdown';
import ExTooltipTypical from '@/components/ex-tooltip-typical';
import ExSummaryInfo from '@/components/ex-summary-info';
import ExInput from '@/components/ex-input';

// const
import {WARNING_MESSAGE_CURRENCY_EXPERIMENTAL, WARNING_MESSAGE_NO_ENOUGH_DATA} from '@/constants/commonMessage';
import {
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
  SPECIAL_CURRENCIES_LIST,
  USDT_LIKE_CURRENCIES_LIST,
} from '@/constants/currencies';
import {
  CALCULATE_OUTGOING,
  CREATE_OUTGOING,
  FETCH_OUTGOING_CURRENCY_PAIRS,
} from '@/constants/events/tunnel/actions.type';

// init
const INIT_CALCULATION = {
  hash: null,
  address: null,
  feePaidBy: null,
  currencyPay: null,
  currencyBuy: null,
  amountType: null,
  amountPay: null,
  amountBuy: null,
  amountReceive: null,
  currencyNetworkFee: null,
  amountNetworkFee: null,
  euroNetworkFee: null,
  rate: null,
  amountFee: null,
  hasRealZeroFee: null,
};

export default {
  name: 'ExOutgoing',
  components: {
    ExInput,
    ExSummaryInfo,
    ExTooltipTypical,
    ExOutgoingCountdown,
    ExAlert,
    ExCoinList,
  },
  mixins: [networkFeeMixin],
  data() {
    const validateMoneyAccount = (rule, value, callback) => {
      if (!validateWalletAddress(this.form.currencyBuy, value)) {
        return callback(new Error(`Please check wallet address format for ${this.form.currencyBuy}`));
      }
      return callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (numberFromLocaleString(this.form.fiatAmount) > this.maxFiatAmount) {
        return callback(new Error('You exceed the balance of the available selling currency'));
      }
      if (numberFromLocaleString(value) <= 0) {
        return callback(new Error('The amount must be greater than zero'));
      }
      return callback();
    };
    const amountsNames = ['fiatAmount', 'cryptoAmount', 'receiveAmount'];

    return {
      form: {
        address: '',
        paidBy: 0,
        currencyPay: '',
        currencyBuy: '',
        fiatAmount: 0,
        cryptoAmount: 0,
        receiveAmount: 0,
        currencyFee: null,
        reference: '',
      },
      calculation: {...INIT_CALCULATION},
      fee: 0,
      locks: [false, true, true],
      highlightCurrencyPay: '',
      highlightCurrencyBuy: '',
      isLoading: false,
      isFetching: false,
      isCalculating: false,
      currenciesBuyList: [],
      currencyPairs: {},
      restartTimerTrigger: true,
      stopTimerTrigger: true,
      amountsNames,
      validateMoneyAccount,
      validateAmount,
      CRYPTO_CURRENCIES,
      WARNING_MESSAGE_CURRENCY_EXPERIMENTAL,
      SPECIAL_CURRENCIES_LIST,
      WARNING_MESSAGE_NO_ENOUGH_DATA,
      ruleRequired,
      ruleMaxLength,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    ...mapState('currentUser', {
      isNetworkFeeAvailable: (state) => !!state.preset.billing.networkFeeWithdraw,
      outgoingCommission: (state) => state.preset.billing.outgoingCommission,
    }),
    ...mapGetters('currentUser', ['hasBillingData']),
    payList() {
      const filterArray = this.currencyPairs[this.form.currencyBuy] ? this.currencyPairs[this.form.currencyBuy] : [];
      return filterCoinByList(this.profile.balance, filterArray).reverse();
    },
    buyList() {
      return filterCoinByList(this.profile.balance, this.currenciesBuyList);
    },
    currencyPayIcon() {
      const coin = getCurrency(this.form.currencyPay);
      return coin.icon;
    },
    currencyBuyIcon() {
      const coin = getCurrency(this.form.currencyBuy);
      return coin.icon;
    },
    maxFiatAmount() {
      const coin = this.profile.balance.find((item) =>
        item.currency === this.form.currencyPay);

      if (coin === undefined || !_.has(coin, 'amount')) return 0;
      if (coin.amount < 0) return 0;

      if (USDT_LIKE_CURRENCIES_LIST.includes(this.form.currencyPay)) {
        const matches = coin.amount.toString().match(/[0-9,]+(\.\d{1,6})?/);
        return Number(matches[0]);
      }

      return coin.amount;
    },
    currencyList() {
      return this.mix_networkFeeMixin.currencyPairList[this.form.currencyBuy]
        ? this.mix_networkFeeMixin.currencyPairList[this.form.currencyBuy]
        : [];
    },
    hasAmount() {
      return this.form.fiatAmount || this.form.cryptoAmount || this.form.receiveAmount;
    },
    networkFeeInfo() {
      if (this.calculation.hash === null) return;
      if ([CRYPTO_CURRENCIES.MATIC_Polygon, FIAT_CURRENCIES.EUR].includes(this.form.currencyFee)) {
        return `${this.calculation.amountNetworkFee} ${this.form.currencyFee}`;
      }
      return `${this.calculation.amountNetworkFee} ${this.form.currencyFee}
        (${this.calculation.euroNetworkFee} ${FIAT_CURRENCIES.EUR})
      `;
    },
  },
  watch: {
    'currencyList'() {
      this.setDefaultCurrencyFee();
    },
    'form.currencyBuy'(value) {
      this.form.address = '';
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      if (this.form.currencyPay === value) {
        this.form.currencyPay = FIAT_CURRENCIES.EUR;
      }

      if (!this.locks[1]) { // cryptoAmount
        this.form.cryptoAmount = cutPrecisionByCurrency(numberFromLocaleString(this.form.cryptoAmount), value);
      } else if (!this.locks[2]) { // receiveAmount
        this.form.receiveAmount = cutPrecisionByCurrency(numberFromLocaleString(this.form.receiveAmount), value);
      }
      this.restartTimer();
    },
    'form.currencyPay'(value) {
      if (this.form.currencyBuy === value) {
        this.form.currencyBuy = CRYPTO_CURRENCIES.BTC;
      }

      if (!this.locks[0]) { // form.fiatAmount
        this.form.fiatAmount = cutPrecisionByCurrency(numberFromLocaleString(this.form.fiatAmount), value);
      }
      this.restartTimer();
    },
    'form.fiatAmount'() {
      if (!this.locks[0]) this.initRequest();
    },
    'form.cryptoAmount'() {
      if (!this.locks[1]) this.initRequest();
    },
    'form.receiveAmount'() {
      if (!this.locks[2]) this.initRequest();
    },
    'form.address'() {
      this.initRequest();
    },
    'form.currencyFee'(newValue, oldValue) {
      if (oldValue) this.initRequest();
    },
    payList(newValue) {
      if (!newValue.find((item) => this.form.currencyPay === item.currency)) {
        this.form.currencyPay = newValue[0].currency;
      }
    },
  },
  created() {
    this.mix_networkFeeMixin_fetchCurrencyPair();
    this.fetchCurrencyPairs();
  },
  methods: {
    fromSecondsToFormat,
    setDefaultCurrencyFee() {
      if (this.currencyList.includes(this.form.currencyBuy)) {
        this.form.currencyFee = this.form.currencyBuy;
      } else if (this.currencyList.length) {
        this.form.currencyFee = this.currencyList[0];
      } else {
        this.form.currencyFee = null;
      }
    },
    restartTimer() {
      if (!this.payList.find((item) => this.form.currencyPay === item.currency)) return;
      this.restartTimerTrigger = !this.restartTimerTrigger;
    },
    handleNetworkFee({type, value}) {
      this.form[type] = value;
    },
    handleChangeFiatAmount(value) {
      this.form.fiatAmount = value;
    },
    handleChangeCryptoAmount(value) {
      this.form.cryptoAmount = value; //
    },
    handleChangeAmountReceiveAmount(value) {
      this.form.receiveAmount = value; //
    },
    currencyType(currencyName) {
      return USDT_LIKE_CURRENCIES_LIST.includes(currencyName) ? 'USDTLike' : isCrypto(currencyName) ? 'crypto' : 'fiat';
    },
    handleChangeLock(item, flag) {
      if (!flag) {
        this.locks = this.locks.map(() => true);
        this.locks[item] = flag;
      }
    },
    getActiveAmount() {
      const name = this.amountsNames.find((item, index) => this.locks[index] === false);
      const result = {};
      result[name] = numberFromLocaleString(this.form[name]);
      return result;
    },
    clearInactive() {
      this.amountsNames.forEach((item, index) => {
        if (this.locks[index] === true) {
          this.form[item] = ' ';
        } else {
          if (this.form[item] === '') {
            this.calculation = Object.assign({}, INIT_CALCULATION);
          }
        }
      });
      this.fee = ' ';
    },
    initRequest: _.debounce(function() {
      // eslint-disable-next-line no-invalid-this
      this.validateAndMakeRequest();
    }, 800),
    async validateAndMakeRequest() {
      if (!this.hasAmount) return;
      this.clearInactive();
      try {
        await this.validateFields();
        if (this.form.address && !validateWalletAddress(this.form.currencyBuy, this.form.address)) return;
        this.calculateOutgoing();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    validateFields() {
      const fields = this.amountsNames.filter((item, index) => !this.locks[index]);
      const validationPromises = [];
      const promiseValidate = this.$refs.form.validateField;
      fields.forEach((item) => {
        const promise = new Promise((((resolve, reject) => {
          promiseValidate(item, (errorMessage) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            if (errorMessage) reject(false);
            resolve(true);
          });
        })));
        validationPromises.push(promise);
      });
      return Promise.all(validationPromises);
    },
    fetchCurrencyPairs() {
      socketRequest.call(this, FETCH_OUTGOING_CURRENCY_PAIRS,
        (payload) => {
          this.prepCurrenciesList(payload);
          this.setDefaultCurrency();
          this.restartTimer();
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      TunnelApi.fetchOutgoingCurrencyPairs(FETCH_OUTGOING_CURRENCY_PAIRS);
    },
    prepCurrenciesList(currencyPairs) {
      if (!_.isArray(currencyPairs)) return;
      const currencies = {};
      currencyPairs.forEach((item) => {
        const pair = getPairCurrencies(item);
        const first = pair.first;
        const second = pair.second;
        if (_.isArray(currencies[first])) {
          currencies[first].push(second);
        } else {
          currencies[first] = [second];
        }
      });
      this.currenciesBuyList = Object.keys(currencies);
      this.currencyPairs = currencies;
    },
    setDefaultCurrency() {
      if (this.currenciesBuyList.includes(CRYPTO_CURRENCIES.BTC)) {
        this.form.currencyBuy = CRYPTO_CURRENCIES.BTC;
      } else {
        this.form.currencyBuy = this.currenciesBuyList[0];
      }
      if (this.currencyPairs[this.form.currencyBuy].includes(FIAT_CURRENCIES.EUR)) {
        this.form.currencyPay = FIAT_CURRENCIES.EUR;
      } else {
        this.form.currencyPay = this.currencyPairs[this.form.currencyBuy][0];
      }
    },
    handleErrorRate() {
      this.clearInactive();
    },
    getActiveAmountInfo() {
      const name = this.amountsNames.find((item, index) => this.locks[index] === false);
      const value = numberFromLocaleString(this.form[name]);
      switch (name) {
        case 'fiatAmount': return {name: 'gross', value};
        case 'cryptoAmount': return {name: 'purchase', value};
        case 'receiveAmount': return {name: 'net', value};
        default: return false;
      }
    },
    calculateOutgoing() {
      socketRequest.call(this, CALCULATE_OUTGOING,
        (payload) => {
          this.updateFormData(payload);
        },
        () => {
          this.clearInactive();
        },
        () => {
          this.isCalculating = false;
        },
      );
      const activeAmountInfo = this.getActiveAmountInfo();
      if (activeAmountInfo === false) return;
      this.isCalculating = true;
      TunnelApi.calculateOutgoing(CALCULATE_OUTGOING, {
        currencyPay: this.form.currencyPay,
        currencyBuy: this.form.currencyBuy,
        amountType: activeAmountInfo.name,
        amount: activeAmountInfo.value,
        address: this.form.address ? this.form.address : undefined,
        currencyNetworkFee: this.form.currencyFee,
      });
    },
    updateFormData(data) {
      this.calculation = Object.assign({}, data);
      if (this.locks[0]) {
        this.form.fiatAmount = data.amountPay;
      }
      if (this.locks[1]) {
        this.form.cryptoAmount = data.amountBuy;
      }
      if (this.locks[2]) {
        this.form.receiveAmount = data.amountReceive;
      }
      this.fee = data.amountFee;
    },
    handleSubmit() {
      this.$refs.form.validate((isValid) => {
        if (!isValid || this.calculation.hash === null) return;
        this.stopTimerTrigger = !this.stopTimerTrigger;

        socketRequest.call(this, CREATE_OUTGOING,
          () => {
            this.$router.push({name: 'BalancesIndex'});
            return 'Request successfully created';
          },
          (payload) => {
            if (payload.error.code === '33:33') {
              // TODO Return error handling as before
              //  `Order limit exceeded: ${payload?.error?.data?.limit} ${data.currency}`
              return payload.error.message;
            }
          },
          () => {
            this.isLoading = false;
          },
        );
        this.isLoading = true;
        const activeAmountInfo = this.getActiveAmountInfo();
        if (activeAmountInfo === false) return;
        TunnelApi.createOutgoing(CREATE_OUTGOING, {
          hash: this.calculation.hash,
          address: this.form.address,
          currencyPay: this.form.currencyPay,
          currencyBuy: this.form.currencyBuy,
          amountType: activeAmountInfo.name,
          amountPay: numberFromLocaleString(this.form.fiatAmount),
          amountBuy: numberFromLocaleString(this.form.cryptoAmount),
          amountReceive: numberFromLocaleString(this.form.receiveAmount),
          currencyNetworkFee: this.calculation.currencyNetworkFee,
          amountNetworkFee: this.calculation.amountNetworkFee,
          hasRealZeroFee: this.calculation.hasRealZeroFee,
          referenceId: this.form.reference,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-outgoing {
  &__form {
    padding: 24px 0 20px;
  }
  &__input-group {
    display: flex;
  }
  &__input-item {
    width: 100%;
  }
  &__image {
    margin-left: 12px;
    width: 40px;
    height: 40px;
  }
  &__coin-list {
    padding-bottom: 15px;
  }
  &__message {
    margin-bottom: 15px;
  }
  &__warning {
    margin-bottom: 26px;
  }
  &__fee-info {
    margin-left: 10px;
  }
  &__label {
    display: inline-block;
    padding-right: 8px;
  }
}
</style>
