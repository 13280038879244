<template>
  <div class="ex-withdrawal-crypto">
    <div class="ex-withdrawal-crypto__form">
      <el-form :model="form" :rules="rules" label-position="top" ref="form">
        <ex-alert v-if="!hasBillingData" type="danger" class="ex-withdrawal-crypto__message">
          {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
        </ex-alert>
        <el-form-item label="Address" prop="destinationAddress">
          <el-input
            v-model.trim="form.destinationAddress"
            placeholder="ex.: 15sBdDJULzqWmnn4KyvqSz1Bz8r7gMwCMQ"
            name="wallet"
            autocomplete="on"
          />
        </el-form-item>
        <el-form-item prop="amount">
          <template #label>
            <div class="ex-withdrawal-crypto__label">Gross amount</div>
            <ex-tooltip-typical>
              Gross amount stands for the total amount before any deductions and fees
            </ex-tooltip-typical>
          </template>
          <el-input placeholder="ex: 100" ref="amount" :value="form.amount" @input="handleInputAmount">
            <el-button slot="append" ref="amount-max" @click="handleInputAmount(maxAmount)">MAX</el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="ex-withdrawal-crypto__coin">
            <ex-coin-list :list="balances" :value="form.currency" @input="form.currency = $event.currency" />
          </div>
          <ex-alert
            v-if="SPECIAL_CURRENCIES_LIST.includes(form.currency)"
            type="warning"
            class="ex-withdrawal-crypto__message"
          >
            {{WARNING_MESSAGE_CURRENCY_EXPERIMENTAL}}
          </ex-alert>
        </el-form-item>
        <el-form-item v-if="auto" prop="amountFee">
          <template #label>
            <div class="ex-withdrawal-crypto__label">Net amount</div>
            <ex-tooltip-typical>
              Net amount stands for the amount left over after all deductions are made
            </ex-tooltip-typical>
          </template>
          <el-input :value="form.amountFee" ref="amount-fee" @input="handleInputAmountFee">
            <el-button slot="append" @click="handleInputAmount(maxAmount)">MAX</el-button>
          </el-input>
        </el-form-item>
        <el-form-item v-if="isNetworkFeeAvailable" prop="currencyFee">
          <template #label>
            <div class="ex-withdrawal-crypto__label">Network Fee Currency</div>
            <ex-tooltip-typical>
              Cost of transferring funds to your wallet on a blockchain which depends on the current network congestion
              and is paid in a chosen currency
            </ex-tooltip-typical>
          </template>
          <el-select v-model="form.currencyFee" :loading="mix_networkFeeMixin.isFetching">
            <el-option v-for="item in currencyList" :key="item" :value="item" />
          </el-select>
          <span v-loading="this.mix_networkFeeMixin.isLoading" class="ex-withdrawal-crypto__fee-info">
            {{mix_networkFeeMixin_info}}
          </span>
        </el-form-item>
        <el-form-item label="Reference" prop="description">
          <el-input v-model.trim="form.description" />
        </el-form-item>
      </el-form>
    </div>
    <div class="ex-withdrawal-crypto__info">
      <ex-summary-info
        :address="form.destinationAddress"
        :gross-amount="form.amount"
        :net-amount="form.amountFee"
        :net-currency="form.currency"
        :network-fee="
          mix_networkFeeMixin.networkFeeParams.feeCurrency
            ? mix_networkFeeMixin.networkFeeParams.feeAmount
            : 0
        "
        :service-fee="amountWithdrawalFee"
        :extended="auto"
      />
      <ex-alert v-if="!hasBillingData" type="danger" class="ex-withdrawal-crypto__message">
        {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
      </ex-alert>
    </div>
    <div class="ex-withdrawal-crypto__action">
      <el-button @click="$router.push({name: 'BalancesIndex'})">CANCEL</el-button>
      <el-button
        type="warning"
        :disabled="maxAmount <= 0 || mix_networkFeeMixin.isLoading || !hasBillingData"
        :loading="isCreating"
        @click="handleCreate"
      >
        SEND
      </el-button>
    </div>
  </div>
</template>

<script>
// utils
import inputmasks from '@/utils/inputmasks';
import {cutPrecisionByCurrency, isTONLike, numberFromLocaleString} from '@/utils/converters/currency';
import {validateWalletAddress} from '@/utils/validate';
import {
  ruleAmount,
  ruleMaxLength,
  ruleRequired,
} from '@/utils/elementUITypicalValidation';

// lib
import {mapGetters, mapState} from 'vuex';

// components
import ExCoinList from '@/components/ex-coin-list';
import ExAlert from '@/components/ex-alert';
import ExSummaryInfo from '@/components/ex-summary-info';
import ExTooltipTypical from '@/components/ex-tooltip-typical';

// mixin
import networkFeeMixin from '@/mixins/networkFeeMixin';

// const
import {USDT_LIKE_CURRENCIES_LIST} from '@/constants/currencies';
import {SPECIAL_CURRENCIES_LIST} from '@/constants/currencies';
import {
  WARNING_MESSAGE_CURRENCY_EXPERIMENTAL,
  WARNING_MESSAGE_NO_ENOUGH_DATA,
} from '@/constants/commonMessage';

export default {
  name: 'ExWithdrawalCrypto',
  components: {
    ExTooltipTypical,
    ExSummaryInfo,
    ExCoinList,
    ExAlert,
  },
  mixins: [networkFeeMixin],
  props: {
    auto: {
      type: Boolean,
      default: false,
    },
    isCreating: {
      type: Boolean,
      default: false,
      required: true,
    },
    balances: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    const validateMoneyAccount = (rule, value, callback) => {
      if (!validateWalletAddress(this.form.currency, value)) {
        return callback(new Error(`Please check wallet address format for ${this.form.currency}`));
      }

      return callback();
    };

    const AMOUNT_TYPE = {NET: 'net', GROSS: 'gross'};

    return {
      form: {
        currency: '',
        destinationAddress: '',
        description: '',
        amount: 0,
        amountFee: 0,
        currencyFee: null,
      },
      rules: {
        destinationAddress: [
          ruleRequired,
          ruleMaxLength(64),
          {validator: validateMoneyAccount, trigger: 'blur'},
        ],
        description: [ruleMaxLength(64)],
        amount: [ruleRequired, ruleAmount],
        amountFee: [ruleRequired, ruleAmount],
        currencyFee: [ruleRequired],
      },
      amountType: '',
      isLoading: false,
      WARNING_MESSAGE_CURRENCY_EXPERIMENTAL,
      SPECIAL_CURRENCIES_LIST,
      AMOUNT_TYPE,
      WARNING_MESSAGE_NO_ENOUGH_DATA,
    };
  },
  computed: {
    ...mapState('currentUser', {
      isNetworkFeeAvailable: (state) => !!state.preset.billing.networkFeeWithdraw,
      withdrawFee: (state) => state.preset.billing.withdrawFee,
    }),
    ...mapGetters('currentUser', ['cryptoBalances', 'currentId', 'hasBillingData']),
    amountWithdrawalFee() {
      return (numberFromLocaleString(this.form.amount) * this.withdrawFee) / 100;
    },
    maxAmount() { // TODO CREATE AND USE UNIFIED METHOD maxAmount
      const coin = this.balances.find((item) => item.currency === this.form.currency);
      if (coin === undefined || !_.has(coin, 'amount')) return 0;
      if (coin.amount < 0) return 0;
      // TODO: HARD CODE! Numeric inputMask doesn't rounds value [https://github.com/RobinHerbots/Inputmask/issues/754]
      return cutPrecisionByCurrency(coin.amount, this.form.currency);
    },
    numberSimbolsAfterComma() { // TODO move to getPrecisionByCurrency
      return USDT_LIKE_CURRENCIES_LIST.includes(this.form.currency) ? 6 : isTONLike(this.form.currency) ? 9 : 8;
    },
    currencyList() {
      return this.mix_networkFeeMixin.currencyPairList[this.form.currency]
        ? this.mix_networkFeeMixin.currencyPairList[this.form.currency]
        : [];
    },
  },
  watch: {
    'currencyList'() {
      this.setDefaultCurrencyFee();
    },
    'form.currency': {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.$emit('updateCurrency', newValue);
        this.form.destinationAddress = '';
        this.mix_networkFeeMixin_resetCurrency();
        this.form.amount = this.form.amount
          ? cutPrecisionByCurrency(numberFromLocaleString(this.form.amount).toFixed(9), newValue)
            .toFixed(9)
            .replace(/\.0+$/, '')
          : 0;
        this.handleInputAmount(this.form.amount);
        if (oldValue) {
          this.makeRequestWithDebounce();
        } else {
          this.form.currencyFee = null;
        }

        this.$nextTick(() => {
          this.$refs.form.clearValidate();
          inputmasks.addMaskByCurrency(newValue, this.$refs.amount);
          if (this.auto) {
            inputmasks.addMaskByCurrency(newValue, this.$refs['amount-fee']);
          }
        });
      },
    },
    'form.currencyFee'(newValue, oldValue) {
      if (oldValue) {
        this.makeRequest();
      }
    },
    'form.destinationAddress'() {
      this.makeRequestWithDebounce();
    },
    'form.amount'() {
      this.makeRequestWithDebounce();
    },
  },
  created() {
    if (this.balances.find((item) => item.currency === this.$route.params.currency)) {
      this.form.currency = this.$route.params.currency;
    } else if (this.balances[0]) {
      this.form.currency = this.balances[0].currency;
    }
  },
  mounted() {
    this.mix_networkFeeMixin_fetchCurrencyPair();
  },
  methods: {
    setDefaultCurrencyFee() {
      if (this.currencyList.includes(this.form.currencyBuy)) {
        this.form.currencyFee = this.form.currencyBuy;
      } else if (this.currencyList.length) {
        this.form.currencyFee = this.currencyList[0];
      } else {
        this.form.currencyFee = null;
      }
    },
    handleInputAmount(amount) {
      const clearAmount = amount === '' ? 0 : numberFromLocaleString(amount);

      if (this.maxAmount >= clearAmount) {
        this.form.amount = amount;
        this.form.amountFee =
          (clearAmount - (clearAmount * this.withdrawFee * 0.01)).toFixed(this.numberSimbolsAfterComma);
      } else {
        this.form.amount = this.maxAmount;
        this.form.amountFee =
          (this.maxAmount - (this.maxAmount * this.withdrawFee * 0.01)).toFixed(this.numberSimbolsAfterComma);
      }
      this.amountType = this.AMOUNT_TYPE.GROSS;
    },
    handleInputAmountFee(amountFee) {
      const clearAmountFee = amountFee === '' ? 0 : numberFromLocaleString(amountFee);

      const amount = (clearAmountFee / ((100 - this.withdrawFee) * 0.01)).toFixed(this.numberSimbolsAfterComma);
      if (this.maxAmount >= amount) {
        this.form.amountFee = amountFee;
        this.form.amount = amount;
      } else {
        this.handleInputAmount(this.maxAmount);
      }
      this.amountType = this.AMOUNT_TYPE.NET;
    },
    handleCreate() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        if (this.auto) {
          this.createWithdrawalAutoCrypto();
        } else {
          this.createWithdrawalManualCrypto();
        }
      });
    },
    createWithdrawalManualCrypto() {
      this.$emit('create', {
        amount: numberFromLocaleString(this.form.amount),
        destinationAddress: this.form.destinationAddress,
        currency: this.form.currency,
        reference: this.form.description,
        hashNetworkFee: this.mix_networkFeeMixin.networkFeeParams.feeHash,
        amountNetworkFee: this.mix_networkFeeMixin.networkFeeParams.feeAmount,
        currencyNetworkFee: this.mix_networkFeeMixin.networkFeeParams.feeCurrency,
        hasRealZeroFee: this.mix_networkFeeMixin.networkFeeParams.hasRealZeroFee,
      });
    },
    createWithdrawalAutoCrypto() {
      this.$emit('create', {
        address: this.form.destinationAddress,
        amountGross: numberFromLocaleString(this.form.amount),
        amountNet: numberFromLocaleString(this.form.amountFee),
        amountType: this.amountType,
        currency: this.form.currency,
        referenceId: this.form.description,
        hashNetworkFee: this.mix_networkFeeMixin.networkFeeParams.feeHash,
        currencyNetworkFee: this.mix_networkFeeMixin.networkFeeParams.feeCurrency,
        amountNetworkFee: this.mix_networkFeeMixin.networkFeeParams.feeAmount,
        hasRealZeroFee: this.mix_networkFeeMixin.networkFeeParams.hasRealZeroFee,
      });
    },
    makeRequestWithDebounce: _.debounce(function() {
      // eslint-disable-next-line no-invalid-this
      this.makeRequest();
    }, 800),
    makeRequest() {
      this.mix_networkFeeMixin_initNetworkFeeState();
      // eslint-disable-next-line no-invalid-this
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        // eslint-disable-next-line no-invalid-this
        // this.fetchNetworkFee();
        this.mix_networkFeeMixin_fetchFee({
          currency: this.form.currency,
          commissionCurrency: this.form.currencyFee,
          amount: numberFromLocaleString(this.form.amount),
          address: this.form.destinationAddress,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-withdrawal-crypto {
  &__message {
    margin: 10px 0 0;
  }
  &__label {
    display: inline-block;
    padding-right: 8px;
  }
  &__form {
    padding-bottom: 10px;
  }
  &__info {
    line-height: 21px;
    padding-bottom: 22px;
    &-highlight {
      font-weight: 700;
    }
  }
  &__action {
    display: flex;
  }
  &__action > * {
    margin-right: 10px;
  }
  &__fee-info {
    margin-left: 10px;
  }
}
</style>
